import React, { FC } from 'react';
import { graphql } from 'gatsby';
import DetailsPageIntro from 'containers/DetailsPageIntro';
import LatestArticles from 'containers/LatestArticles';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';

const ArticleDetailsPage: FC<{ data: ArticleDetailsTypes.ArticleDetailsSiteTypes }> = ({
  data: {
    articleDetails: {
      pageName,
      urls,
      seo,
      title: introductionTitle,
      bodyText,
      mediaLinksText,
      socialMediaShare,
      relatedProducts: { relatedProductsTitle: relatedArticlesTitle },
    },
    allArticleDetails: { nodes: articles },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Breadcrumbs />
      <DetailsPageIntro
        title={introductionTitle}
        text={bodyText}
        socialTitle={mediaLinksText}
        socialMedia={socialMediaShare}
      />
      <LatestArticles sectionTitle={relatedArticlesTitle} articles={articles} />
    </Layout>
  );
};

export const query = graphql`
  query ArticleDetailsPageQuery($lang: String, $url: String, $relatedArticlesLinks: [String]) {
    articleDetails(lang: { eq: $lang }, url: { eq: $url }) {
      ...ArticleDetailsPageFragment
    }
    allArticleDetails(filter: { lang: { eq: $lang }, url: { in: $relatedArticlesLinks } }) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

export default ArticleDetailsPage;
