import React, { FC } from 'react';
import SocialMedia from 'containers/SocialMedia';

import { Container } from 'layout';
import Typography from 'common/Typography';

import { DetailsPageIntroProps } from './models';

import './DetailsPageIntro.scss';

const DetailsPageIntro: FC<DetailsPageIntroProps> = ({ title, text, socialTitle, socialMedia }) => (
  <Container fluid noPadding className="details-page-intro" data-testid="details-page-intro">
    <Container className="details-page-intro__wrapper">
      <Typography variant="heading2" weight="medium" customClass="details-page-intro__title">
        {title}
      </Typography>
      <Typography
        variant="paragraph1"
        dangerouslySetInnerHTML={text}
        customClass="details-page-intro__text"
      />
      <section className="details-page-intro__social">
        <Typography variant="label" customClass="details-page-intro__social-title">
          {socialTitle}
        </Typography>
        <SocialMedia socialMediaShare={socialMedia} articleTitle={title} />
      </section>
    </Container>
  </Container>
);

export default DetailsPageIntro;
